import { Component, Input} from '@angular/core';
import { SampleNor } from '../../models/sample-nor';


@Component({
  selector: 'nemo-sample-nors-table',
    template: `
    <b> Sample NOR info </b>
    <table mat-table [dataSource]="_sampleNors">
      <ng-container matColumnDef="request_id">
	<th mat-header-cell *matHeaderCellDef>Request ID</th>
	<td mat-cell *matCellDef="let item"> {{ item.requestAC}} </td>
      </ng-container>

      <ng-container matColumnDef="request_title">
	<th mat-header-cell *matHeaderCellDef>Request Title</th>
	<td mat-cell *matCellDef="let item"> {{ item.requestTitle}} </td>
      </ng-container>

      <ng-container matColumnDef="sample_name">
	<th mat-header-cell *matHeaderCellDef>Sample Name</th>
	<td mat-cell *matCellDef="let item"> {{ item.sampleName}} </td>
      </ng-container>

      <ng-container matColumnDef="requested_nor">
      <th mat-header-cell *matHeaderCellDef>Requested Reads</th>
	<td mat-cell *matCellDef="let item"> {{ item.requestedNorM * 1000000  | number : fractionSize }} </td>
      </ng-container>

      <ng-container matColumnDef="actual_nor">
	<th mat-header-cell *matHeaderCellDef>Actual Reads</th>
	<td mat-cell *matCellDef="let item"> {{ item.actualNor | number : fractionSize }} </td>
      </ng-container>

      <ng-container matColumnDef="nor_ratio">
	<th mat-header-cell *matHeaderCellDef>Reads Ratio(Actual/Requested)</th>
	<td mat-cell *matCellDef="let item"> {{ item.actualNor / (item.requestedNorM * 1000000) | number : '1.2-2'  }} </td>
      </ng-container>

      <ng-container matColumnDef="qc_check">
	<th mat-header-cell *matHeaderCellDef>QC Check</th>
	<td mat-cell *matCellDef="let item"> {{ item.qcCheck}} </td>
      </ng-container>

      <ng-container matColumnDef="requester">
	<th mat-header-cell *matHeaderCellDef>Requester</th>
	<td mat-cell *matCellDef="let item"> {{ item.requester }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `,
  styles: [`
    table {
      width: 100%;
    }
  `]
})
export class SampleNorsTableComponent {

  _sampleNors: Array<SampleNor>;
  displayedColumns: string[] = [
      'request_id',
      'request_title',
      'sample_name',
      'requested_nor',
      'actual_nor',
      'nor_ratio',
      'qc_check',
      'requester'
  ];
  @Input() set sampleNors(value: Array<SampleNor>) {
    if (value) {
      this._sampleNors = [...value];
      this._sampleNors.sort((a, b) => a.qcCheck.localeCompare(b.qcCheck));
    }
  }
}
