import { Component, OnDestroy, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NRequest } from '../../models/genomics-request';
import {
  findRequestByAccessionCodeFromRequestPage,
  findRequestByAccessionCodePending,
  getGenomicsCurrentRequest
} from '../../store';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { Title } from '@angular/platform-browser';
import { findTaskByRequest } from '../../../tasks/store/task.action';
import { getTasks } from '../../../tasks/store/task.selectors';
import { Task } from 'src/app/tasks/models/task.model';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationOkComponent } from '../../../shared/shared/components/dialog-confirmation-ok/dialog-confirmation-ok.component';
import { filter, take } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'nemo-request-view',
  template: `
      <nemo-request-page-title [request]="request$ | async"></nemo-request-page-title>
      <nemo-request-stages
        [request]="request$ | async"
        [isLaber]="isLaber$ | async"
        [showNames]="true"
      ></nemo-request-stages>
      <mat-checkbox
        color="primary"
        checked="true"
        (change)="changeFilter($event)">
        Hide archived
      </mat-checkbox>

      <div class="request-info">
          <nemo-request-info [request]="request$ | async"></nemo-request-info>
          <nemo-request-samples
                  [request]="request$ | async"
                  [isLaber]="isLaber$ | async"
          ></nemo-request-samples>
      </div>
  `,
  styles: [`
                 div.request-info {
                     padding-right: 50px;
                 }
             `],
})
export class RequestPageComponent
  extends HasGlobalContextUserRole
  implements OnInit, OnDestroy {

  paramSubscription: Subscription;
  request$: Observable<NRequest>;
  tasks$: Observable<Task[]>;

  hideArchived = true;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    super(store);
  }

  ngOnInit() {
    this.tasks$ = this.store.select((getTasks));
    this.request$ = this.store.select(getGenomicsCurrentRequest).pipe(

      filter(req => Boolean(req))
    );

    this.loadDetail();
    this.route.queryParams.pipe(
      take(1)
    ).subscribe(queryParams => {
      if (queryParams['submitted']) {
        this.dialog.open(DialogConfirmationOkComponent, {
          width: '500px',
          data: {
            message:
              'Your request was submitted and will be handled by our team.' +
              'You can follow progress in the right pane and will be notified by emails.'
          }
        });
      }
    });
  }

  loadDetail() {
    this.paramSubscription = this.route.params.subscribe(params => {
      // Load new request may take a few seconds.
      // load an empty request first to avoid user accidentally operates on old request page
      this.store.dispatch(findRequestByAccessionCodePending());
      const accessionCode = params['accessionCode'];
      this.titleService.setTitle(`Nemo request ${formatAccessionCode(accessionCode)}`);
      this.store.dispatch(findRequestByAccessionCodeFromRequestPage({
        accessionCode: accessionCode,
        hideArchived: this.hideArchived
      }));
      this.store.dispatch(findTaskByRequest({requestAccessionCode: accessionCode}));
    });
  }

  changeFilter(event: MatCheckboxChange): void {
    this.hideArchived = event.checked;
    this.loadDetail();
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }
}
