import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';

import { AppState } from '../../../../store/app.reducers';
import { selectActiveStudies } from '../../../store';
import { Study } from '../../../models/Study';
import { HasGlobalContextUserRole } from '../../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { getArchiveFilter } from '../../../../table/store';
import { selectGlobalContextUserAndRoleAndLab } from '../../../../global-context/store/global-context.selectors';
import { UserRole } from '../../../../global-context/models/user-role';

@Component({
  selector: 'nemo-study-select',
  template: `
    <mat-form-field
      class="example-full-width"
    >
      <mat-label>Study</mat-label>
      <mat-select
        [formControl]="control"
        (selectionChange)="changedValue($event.value)"
      >
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let study of options$ | async" [value]="study.id">
          {{study.name}}
        </mat-option>
      </mat-select>

    </mat-form-field>
  `,
  styles: [`
             mat-form-field {
               width: 20em;
             }
           `
  ]
})
// TODO an archived study associated to a request will appear as none when editing the request, propagating null if it's modified
export class StudySelectComponent extends HasGlobalContextUserRole implements OnInit {
  @Input()
  control: UntypedFormControl;

  @Output()
  changeValue = new EventEmitter<number>();

  options$: Observable<Study[]>;

  constructor(
    private store: Store<AppState>
  ) {
    super(store);
  }

  ngOnInit() {
    this.options$ = this.store.select(selectActiveStudies).pipe(
      
      withLatestFrom(
        this.store.
          select((selectGlobalContextUserAndRoleAndLab)
        )
      ),
      map(([xs, userRoleLab]) => _.chain(xs)
        .orderBy('name')
        .filter((x: Study) => userRoleLab.role === UserRole.LABER || x.createdBy.id === userRoleLab.user.id)
        .value()
      )
    );
  }

  changedValue(studyId: number) {
    this.changeValue.emit(studyId);
  }

}
