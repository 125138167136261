import { User } from '../../user/models/user.model';
import { FileDto } from '../../shared/models/file-dto';
import { UserDTO } from '../../user/models/user.dto';
import PlateDimensions from '../../labware/models/plate-dimensions';
import { Department } from '../../company/models/Department';
import { Project } from '../../company/models/Project';
import { PoolSampleMixDto, PoolSamplePlaceholderDto } from '../../bio/dto/bio.dto';
import { TaskAvailable, TaskType } from '../../tasks/models/task.model';
import { Study } from '../../company/models/Study';

export interface SamplePlateDto {
  id?: number;
  accessionCode: string;
  title: string;
  dimensions: PlateDimensions;
  layout: PoolWithCoordinatesDto[];
  availableForTasks: TaskAvailable[];
}

export interface PoolWithCoordinatesDto {
  plateLocation: {
    plateAccessionCode?: string,
    coordinates: { row: number, column: number }
  };
  content: PoolSamplePlaceholderDto | PoolSampleMixDto;
}

export interface RequestSampleContainersDto {
  plates: [SamplePlateDto];
  pools: [PoolSamplePlaceholderDto | PoolSampleMixDto];
}

export interface SimpleRequestDto {
  accessionCode: string;
  requesterId: string;
  assignedToIds: string[];
  title: string;
  description: string;
  createdAt: Date;
  libraryMethod: string | null;
  phiXPercentage: number;
}

/**
 * The one we receive from backend.
 */
export interface RequestSummaryDTO {
  accessionCode: string;
  requester: UserDTO;
  assignedTo: UserDTO[];
  createdAt: string;
  submittedAt?: string;
  libraryMethod?: string;
  phiXPercentage: number;
  title: string;
  description: string;
  department: Department;
  project: Project;
  study: Study;
  numberOfSamples: number;
  totalRequestedNor: string;
  stages: RequestStageDto[];
  archived: boolean;
  tasks: TaskType[];
  collaborator: string;
  fastqDir: string;
}

/**
 * The one we receive from backend.
 */
export interface RequestDetailedDTO extends RequestSummaryDTO {
  plates: Array<SamplePlateDto>;
  pools: Array<PoolSampleMixDto>;
}

export interface RequestStageDto {
  name: string;
  completedAt?: Date;
  completedBy?: UserDTO;
}

/**
 * The one we send from the form.
 */
export interface CreateRequestDTO {
  libraryMethod?: string;
  phiXPercentage: number;
  title: string;
  description?: string;
  sampleFile?: FileDto;
  sampleIndexHeader?: number;
  departmentCode?: number;
  projectCode?: number;
  studyId?: number;
  collaborator: string;
}

export class UpdateRequestDTO {
  constructor(
    public accessionCode?: string,
    public title: string = '',
    public phiXPercentage: number = 0,
    public libraryMethodName?: string,
    public description: string = '',
    public departmentCode?: number,
    public projectCode?: number,
    public studyId?: number,
    public collaborator: string = 'N/A'
  ) {
  }
}

export const createRequestFixture = {
    requester: {name: 'zzz'} as User,
    workflow: 'mRNA_stranded',
    title: 'some title',
    description: 'my_description',
    sampleFile: {
      file: 'data:text/csv;base64,V2VsbCxTYW1wbGUgSUQsQ29uYy4gK…5LDcuMywwLjgNCkg5LFNhbXBIOSw2LjU2MDUsNy4yLDAuOA0K',
      filename: '2019-01-10_15H_56M_Quality_Table_bOB.csv'
    },
    phiXPercentage: 12.34,
    collaborator: 'SRA'
  };

export const updateRequestFixture = {
  accessionCode: 'REQ123',
  title: 'some title',
  phiXPercentage: 12.34,
  libraryMethodName: 'mRNA_stranded',
  description: 'my_description',
  departmentCode: 31,
  projectCode: 42,
  collaborator: 'SRA'
};
