export const environment = {
  production: true,
  baseUrl: window.location.origin,
  apiUrl: '/api',
  auth: {
    issuer: 'https://accounts.google.com',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid profile email',
    clientId: '544044007823-ig08o8iqkjqap8miunugt4pgms6entr4.apps.googleusercontent.com',
    silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
    showDebugInformation: false,
    timeoutFactor: 0.75
  },
  doc: {
    howToSubmitter: 'https://docs.google.com/document/d/1ntFxDPHB9ok3BkvKobo1my_g0jybD9fXhelGWevAYSo'
  }
};
