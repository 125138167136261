import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';

import { AppState } from '../../../../store/app.reducers';
import { selectCompanyActiveProjects } from '../../../store';
import { Project } from '../../../models/Project';

@Component({
  selector: 'nemo-project-select',
  template: `
    <mat-form-field class="example-full-width">
      <mat-label>Project (*Required)</mat-label>
      <mat-select
        [formControl]="control"
        (selectionChange)="changedValue($event.value)"
      >
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let project of options$ | async" [value]="project.code">
          [{{project.code}}] {{project.name}}
        </mat-option>
      </mat-select>

    </mat-form-field>
  `,
  styles: [`
    mat-form-field {
      width: 20em;
    }
  `
  ]
})
export class ProjectSelectComponent implements OnInit {
  @Input()
  control: UntypedFormControl;

  @Output()
  changeValue = new EventEmitter<number>();

  options$: Observable<Project[]>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.options$ = this.store.select(selectCompanyActiveProjects).pipe(
      
      map((projects) => _.orderBy(projects, [project => project.name.toLowerCase()]))
    );
  }

  changedValue(deptCode: number) {
    this.changeValue.emit(deptCode);
  }

}
