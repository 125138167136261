import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';

import { AppState } from '../../../../store/app.reducers';
import { selectCompanyActiveDepartments } from '../../../store';
import { Department } from '../../../models/Department';

@Component({
  selector: 'nemo-department-select',
  template: `
    <mat-form-field
      class="example-full-width"
    >
      <mat-label>Department (*Required)</mat-label>
      <mat-select
        [formControl]="control"
        (selectionChange)="changedValue($event.value)"
      >
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let dept of options$ | async" [value]="dept.code">
          [{{dept.code}}] {{dept.name}}
        </mat-option>
      </mat-select>

    </mat-form-field>
  `,
  styles: [`
    mat-form-field {
      width: 20em;
    }
  `
  ]
})
export class DepartmentSelectComponent implements OnInit {
  @Input()
  control: UntypedFormControl;

  @Output()
  changeValue = new EventEmitter<number>();

  options$: Observable<Department[]>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.options$ = this.store.select(selectCompanyActiveDepartments).pipe(
      
      map((departments) => _.orderBy(departments, [department => department.name.toLowerCase()]))
    );
  }

  changedValue(deptCode: number) {
    this.changeValue.emit(deptCode);
  }

}
